import lodash from "lodash";
import store from '../store';
import Fuse from "fuse.js";
import axios from "axios";

const helpFunctions = {
    modal_functions: {
        openModal(ref) {
            this.$refs[ref].open();
        },
        closeModal(ref) {
            this.$refs[ref].close();
        },
    },
    tags: [],
    reg_exp: "",
    fuse_options: {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        minMatchCharLength: 3,
        threshold: 0.3,
    },
    dialog_options: {
        top: 50,
        left: window.innerWidth/4 - 200,
        buttonPin: false,
        buttonClose: false,
        pinned: false
    },
    footer_props: {
        itemsPerPageOptions: [
            {value:15, title:'15'},
            {value:25, title:'25'},
            {value:50, title:'50'},
            {value:75, title:'75'},
            {value:100, title:'100'},
            {value:125, title:'125'},
            {value:150, title:'150'},
            {value:175, title:'175'},
            {value:200, title:'200'},
            {value:250, title:'250'},
            {value:300, title:'300'}
        ],
        itemsPerPageText: "Rows per page:",
    },
    table_options: {
        itemsPerPage: 15,
        page: 1,
        sortBy: [{"key": "id", "order": "asc"}]
    },
    empty_margins: {
        margin_top: 0,
        margin_bottom: 0,
        margin_left: 0,
        margin_right: 0,
    },
    margins: {
        margin_top: 20,
        margin_bottom: 20,
        margin_left: 20,
        margin_right: 20,
    },
    models_for_audits_table: [
        'ambienttemperature',
        'brand',
        'company',
        'contact',
        'conformitymark',
        'distributiondevicefunction',
        'distributionpanel',
        'employee',
        'generaldefect',
        'inspectioncertificate',
        'inspectioncode',
        'inspectioninstitute',
        'inspectioninstitutecertification',
        'inspectionzone',
        'ipindication',
        'iptype',
        'linevoltage',
        'note',
        'simpledefect',
        'simpleinspection',
        'simpleequipmenttype',
        'simpleprotectionmethod',
        'tag',
        'template',
        'workflow',
        'zonetype'
    ],
    models_for_fields_table: [
        { value: "AmbientTemperature", text: store.getters.translate("ambienttemperatures") },
        { value: "Audit", text: store.getters.translate("audits") },
        { value: "Brand", text: store.getters.translate("brands") },
        { value: "Company", text: store.getters.translate("companies") },
        { value: "Contact", text: store.getters.translate("contacts") },
        { value: "ConformityMark", text: store.getters.translate("conformitymarks") },
        //NO NEED TO SET WHAT FIELDS TO SHOW OR HIDE FOR CUSTOM PERMISSIONS
        //{ value: "CustomPermission", text: store.getters.translate("custompermissions") },
        { value: "DistributionPanel", text: store.getters.translate("distributionpanels") },
        { value: "DistributionDeviceFunction", text: store.getters.translate("distributiondevicefunctions") },
        { value: "Employee", text: store.getters.translate("employees") },
        { value: "GeneralDefect", text: store.getters.translate("generaldefects") },
        { value: "InspectionCertificate", text: store.getters.translate("inspectioncertificates") },
        { value: "InspectionCode", text: store.getters.translate("inspectioncodes") },
        { value: "InspectionInstitute", text: store.getters.translate("inspectioninstitutes") },
        { value: "InspectionInstituteCertification", text: store.getters.translate("inspectioninstitutecertifications") },
        { value: "InspectionZone", text: store.getters.translate("inspectionzones") },
        { value: "InspectionQuestion", text: store.getters.translate("inspectionquestions") },
        { value: "InspectionAnswer", text: store.getters.translate("inspectionanswers") },
        { value: "IpIndication", text: store.getters.translate("ipindications") },
        { value: "IpType", text: store.getters.translate("iptypes") },
        { value: "LineVoltage", text: store.getters.translate("linevoltage") },
        { value: "Note", text: store.getters.translate("notes") },
        { value: "Role", text: store.getters.translate("roles") },
        { value: "SimpleDefect", text: store.getters.translate("simpledefects") },
        { value: "SimpleInspection", text: store.getters.translate("simpleinspections") },
        { value: "SimpleInspectionItem", text: store.getters.translate("simpleinspectionitems") },
        { value: "SimpleEquipmentType", text: store.getters.translate("simpleequipmenttypes") },
        { value: "SimpleProtectionMethod", text: store.getters.translate("simpleprotectionmethods") },
        { value: "Tag", text: store.getters.translate("tags") },
        { value: "Template", text: store.getters.translate("templates") },
        { value: "Token", text: store.getters.translate("tokens") },
        { value: "Translation", text: store.getters.translate("translations") },
        { value: "User", text: store.getters.translate("users") },
        { value: "Workflow", text: store.getters.translate("workflows") },
        { value: "ZoneType", text: store.getters.translate("zonetypes") },
    ],
    models: {
        ambienttemperatures: 'AmbientTemperature',
        audits: 'Audit',
        brands: 'Brand',
        companies: 'Company',
        contacts: 'Contact',
        conformitymarks: 'ConformityMark',
        custom_permissions: 'CustomPermission',
        distributiondevicefunctions: 'DistributionDeviceFunction',
        distributionpanels: 'DistributionPanel',
        employees: 'Employee',
        generaldefects: 'GeneralDefect',
        inspectioncertificates: 'InspectionCertificate',
        inspectioncodes: 'InspectionCode',
        inspectioninstitutes: 'InspectionInstitute',
        inspectioninstitutecertifications: 'InspectionInstituteCertification',
        inspectionzones: 'InspectionZone',
        inspectionquestions: 'InspectionQuestion',
        inspectionanswers: 'InspectionAnswer',
        ipindications: 'IpIndication',
        iptypes: 'IpType',
        linevoltage: 'LineVoltage',
        notes: 'Note',
        roles: 'Role',
        simpledefects: 'SimpleDefect',
        simpleinspections: 'SimpleInspection',
        simpleinspectionitems: 'SimpleInspectionItem',
        simpleequipmenttypes: 'SimpleEquipmentType',
        simpleprotectionmethods: 'SimpleProtectionMethod',
        tags: 'Tag',
        templates: 'Template',
        tokens: 'Token',
        translations: 'Translation',
        users: 'User',
        workflows: 'Workflow',
        zonetypes: 'ZoneType',
        //models without fields
        addresses: 'Address',
        emailaddresses: 'Emailaddress',
        fields: 'Field',
        people: 'Person',
        search: 'Search',
    },
    field_types: [
        { value: "text_field", text: store.getters.translate("text_field") },
        { value: "text_area", text: store.getters.translate("text_area") },
        { value: "boolean", text: store.getters.translate("boolean") },
        { value: "select_single", text: store.getters.translate("select_single") },
        { value: "select_multiple", text: store.getters.translate("select_multiple") },
        { value: "date", text: store.getters.translate("date") },
        { value: "read_only", text: store.getters.translate("read_only") },
        { value: "link", text: store.getters.translate("link") },
    ],
    can_search: [
        'notes',
        'companies',
        'templates',
        'contacts',
        'employees'
    ],
    inspection_questions_headers: [
        { title: store.getters.translate("name"), value: "name", align: "start", class: 'sticky-header', sortable: true },
        { title: store.getters.translate("required"), value: "required", align: "start", class: 'sticky-header', sortable: true },
        { title: store.getters.translate("ok"), value: "ok", align: "start", class: 'sticky-header', sortable: true },
        { title: store.getters.translate("n_a"), value: "n_a", align: "start", class: 'sticky-header', sortable: true },
        { title: store.getters.translate("fail"), value: "fail", align: "start", class: 'sticky-header', sortable: true },
        { title: store.getters.translate("comment"), value: "comment", align: "start", class: 'sticky-header', sortable: false },
    ],
    pages: {
        ambienttemperatures: {
            title: store.getters.translate("ambienttemperatures"),
            name: "ambienttemperatures",
            model: "ambienttemperature",
        },
        brands: {
            title: store.getters.translate("brands"),
            name: "brands",
            model: "brand",
        },
        companies: {
            title: store.getters.translate("companies"),
            name: "companies",
            model: "company"
        },
        contacts: {
            title: store.getters.translate("contacts"),
            name: "contacts",
            model: "contact",
        },
        conformitymarks: {
            title: store.getters.translate("conformitymarks"),
            name: "conformitymarks",
            model: "conformitymark",
        },
        custompermissions: {
            title: store.getters.translate("create_custom_permission"),
            name: "custom_permissions",
        },
        dashboard: {
            title: store.getters.translate("dashboard"),
            name: "dashboard",
        },
        distributiondevicefunctions: {
            title: store.getters.translate("distributiondevicefunctions"),
            name: "distributiondevicefunctions",
            model: "distributiondevicefunction",
        },
        distributionpanels: {
            title: store.getters.translate("distributionpanels"),
            name: "distributionpanels",
            model: "distributionpanel",
        },
        employees: {
            title: store.getters.translate("employees"),
            name: "employees",
            model: "employee",
        },
        fields: {
            title: store.getters.translate("field"),
            name: "fields",
            model: "field",
        },
        generaldefects: {
            title: store.getters.translate("generaldefects"),
            name: "generaldefects",
            model: "generaldefect",
        },
        inspectioncertificates: {
            title: store.getters.translate("inspectioncertificates"),
            name: "inspectioncertificates",
            model: "inspectioncertificate",
        },
        inspectioncodes: {
            title: store.getters.translate("inspectioncodes"),
            name: "inspectioncodes",
            model: "inspectioncode",
        },
        inspectioninstitutes: {
            title: store.getters.translate("inspectioninstitutes"),
            name: "inspectioninstitutes",
            model: "inspectioninstitute",
        },
        inspectioninstitutecertifications: {
            title: store.getters.translate("inspectioninstitutecertifications"),
            name: "inspectioninstitutecertifications",
            model: "inspectioninstitutecertification",
        },
        inspectionzones: {
            title: store.getters.translate("inspectionzones"),
            name: "inspectionzones",
            model: "inspectionzone",
        },
        inspectionquestions: {
            title: store.getters.translate("inspectionquestions"),
            name: "inspectionquestions",
            model: "inspectionquestion",
        },
        inspectionanswers: {
            title: store.getters.translate("inspectionanswers"),
            name: "inspectionanswers",
            model: "inspectionanswer",
        },
        ipindications: {
            title: store.getters.translate("ipindications"),
            name: "ipindications",
            model: "ipindication",
        },
        iptypes: {
            title: store.getters.translate("iptypes"),
            name: "iptypes",
            model: "iptype",
        },
        linevoltage: {
            title: store.getters.translate("linevoltage"),
            name: "linevoltage",
            model: "linevoltage",
        },
        notes: {
            title: store.getters.translate("note"),
            name: "notes",
            model: "note"
        },
        people: {
            title: store.getters.translate("people"),
            name: "people",
        },
        profile: {
            title: store.getters.translate("profile"),
            name: "profile",
        },
        roles: {
            title: store.getters.translate("roles"),
            name: "roles",
            model:'role'
        },
        search: {
            title: store.getters.translate("search"),
            name: "search",
            model: "search",
        },
        settings: {
            title: store.getters.translate("settings"),
            name: "settings",
        },
        simpledefects: {
            title: store.getters.translate("simpledefects"),
            name: "simpledefects",
            model: "simpledefect",
        },
        simpleinspections: {
            title: store.getters.translate("simpleinspections"),
            name: "simpleinspections",
            model: "simpleinspection"
        },
        simpleinspectionitems: {
            title: store.getters.translate("simpleinspectionitems"),
            name: "simpleinspectionitems",
            model: "simpleinspectionitem"
        },
        simpleequipmenttypes: {
            title: store.getters.translate("simpleequipmenttypes"),
            name: "simpleequipmenttypes",
            model: "simpleequipmenttype",
        },
        simpleprotectionmethods: {
            title: store.getters.translate("simpleprotectionmethods"),
            name: "simpleprotectionmethods",
            model: "simpleprotectionmethod",
        },
        tags: {
            title: store.getters.translate("tag"),
            name: "tags",
            model: "tag"
        },
        templates: {
            title: store.getters.translate("templates"),
            name: "templates",
            model: "template"
        },
        tokens: {
            title: store.getters.translate("tokens"),
            name: "tokens",
            model: "token"
        },
        translations: {
            title: store.getters.translate("translations"),
            name: "translations",
            model: "translation",
        },
        workflows: {
            title: store.getters.translate("workflows"),
            name: "workflows",
            model: 'workflow'
        },
        zonetypes: {
            title: store.getters.translate("zonetypes"),
            name: "zonetypes",
            model: 'zonetype'
        },
    },
    base_table_options: {
        ambienttemperatures: {
            no_import: true,
            no_tags: true,
        },
        brands: {
            no_import: true,
            no_tags: true,
        },
        companies: {
            avatar: true,
        },
        contacts: {
            avatar: true,
        },
        conformitymarks: {
            no_import: true,
            no_tags: true,
        },
        distributiondevicefunctions: {
            no_import: true,
            no_tags: true,
        },
        distributionpanels: {
            no_import: true,
            no_tags: true,
        },
        employees: {
            avatar: true,
        },
        fields: {
            no_import: true,
            no_tags: true,
            no_select: true,
        },
        generaldefects: {
            no_import: true,
            no_tags: true,
        },
        inspectioninstitutes: {
            no_import: true,
            no_tags: true,
        },
        inspectioninstitutecertifications: {
            no_import: true,
            no_tags: true,
        },
        inspectioncodes: {
            no_import: true,
            no_tags: true,
        },
        inspectioncertificates: {
            no_import: true,
            no_tags: true,
        },
        inspectionzones: {
            no_import: true,
            no_tags: true,
        },
        inspectionquestions: {
            no_import: true,
            no_tags: true,
        },
        inspectionanswers: {
            no_import: true,
            no_tags: true,
        },
        ipindications: {
            no_import: true,
            no_tags: true,
        },
        iptypes: {
            no_import: true,
            no_tags: true,
        },
        linevoltage: {
            no_import: true,
            no_tags: true,
        },
        notes: {
            no_import: true,
            copy: true,
            no_create: true
        },
        roles: {
            no_import: true,
            no_tags: true,
            no_select: true
        },
        simpledefects: {
            no_import: true,
            no_tags: true,
        },
        simpleinspections: {
            no_import: true,
            no_tags: true,
        },
        simpleequipmenttypes: {
            no_import: true,
            no_tags: true,
        },
        simpleprotectionmethods: {
            no_import: true,
            no_tags: true,
        },
        tags: {
            no_import: true,
            no_tags: true,
        },
        templates: {
            no_import: true,
            copy: true,
        },
        translations: {
            no_import: true,
            no_tags: true,
        },
        workflows: {
            no_import: true,
            no_tags: true,
        },
        zonetypes: {
            no_import: true,
            no_tags: true,
        },
    },
    base_table_includable_options: {
        companies: {
            employees: {
                create: true,
                link: true,
                detachable: true,
                show_search: true,
            },
            contacts: {
                create: true,
                link: true,
                detachable: true,
                show_search: true,
            },
            notes: {
                create: true,
                show_search: true,
            }
        },
        contacts: {
            companies: {
                link: true,
                detachable: true,
                show_search: true,
            },
            notes: {
                create: true,
                show_search: true,
            }
        },
        employees: {
            roles: {
                link: true,
                detachable: true,
                show_search: true,
            },
            tokens: {
                create: true,
                show_search: true,
            },
            companies: {
                link: true,
                detachable: true,
                show_search: true,
            },
            notes: {
                create: true,
                show_search: true,
            }
        },
        inspectionzones: {
            simpleinspectionitems: {
                create: true,
                copy: true,
                show_search: true,
            },
        },
        simpleinspections: {
            inspectionzones: {
                create: true,
                show_search: true,
            },
            simpleinspectionitems: {
                create: true,
                copy: true,
                show_search: true,
            },
            generaldefects: {
                create: true,
                show_search: true,
            },
            simpledefects: {
                show_search: true,
            },
        },
        simpleinspectionitems: {
            simpledefects: {
                create: true,
                show_search: true,
            },
        },
        search: {
            notes: {
                no_select: true,
            },
            companies: {
                no_select: true,
            },
            contacts: {
                no_select: true,
            },
            employees: {
                no_select: true,
            },
        },
    },
    default_trigger: {
        workflow_id: null,
        model: "",
        type: "added_tag",
        from: "",
        to: "",
        operator: "=",
        parameters: {
            operator: "=",
            compared_value: "",
            compared_type: "",
        },
        amount_of_repeats: 1,
    },
    default_action: {
        workflow_id: null,
        type: "notify_users",
        models: [],
        message: "",
        parameters: {
            generate_document: false,
            new_note: false,
            template_id: null,
            document_name: null,
            change_status: null,
            attach_to: [],
            linked_model_attach_to: [],
            tree: null,
            send_email: false,
            tags: [],
            only_latest_record: false,
            repeat: false
        },
    },
    trigger_types: [
        {
            value: "added_tag",
            text: store.getters.translate("added_tag"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "tag_exist",
            text: store.getters.translate("tag_exist"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "field_changes",
            text: store.getters.translate("field_changes"),
            icon: "mdi-form-textbox",
        },
        {
            value: "scheduler",
            text: store.getters.translate("scheduler"),
            icon: "mdi-calendar",
        },
        {
            value: "before_date",
            text: store.getters.translate("before_date"),
            icon: "mdi-calendar",
        },
        {
            value: "after_date",
            text: store.getters.translate("after_date"),
            icon: "mdi-calendar",
        },
    ],
    action_types: [
        {
            value: "notify_current_user",
            text: store.getters.translate("notify_current_user"),
            icon: "mdi-message-processing-outline",
        },
        {
            value: "notify_users",
            text: store.getters.translate("notify_users"),
            icon: "mdi-android-messages",
        },
        {
            value: "notify_roles",
            text: store.getters.translate("notify_roles"),
        },
        {
            value: "notify_model",
            text: store.getters.translate("notify_model"),
            icon: "mdi-account-arrow-right",
        },
        {
            value: "attach_tag",
            text: store.getters.translate("attach_tag"),
            icon: "mdi-tag-plus-outline",
        },
        {
            value: "remove_tag",
            text: store.getters.translate("remove_tag"),
            icon: "mdi-tag-remove-outline",
        },
        {
            value: "create_note",
            text: store.getters.translate("create_note"),
            icon: "mdi-note-plus",
        },
        {
            value: "change_status",
            text: store.getters.translate("change_status"),
            icon: "mdi-traffic-light",
        },
    ],
    //BASE TABLE AND BASE LINK FUNCTIONS
    sortByTagsBase(sorted_array, tags) {
        var response = [];
        for (let i = 0; i < sorted_array.length; i++) {
            if (sorted_array[i].tags.length > 0) {
                var found = false;
                for (let x = 0; x < sorted_array[i].tags.length; x++) {
                    for (let y = 0; y < tags.length; y++) {
                        if (sorted_array[i].tags[x] == tags[y]) {
                            response.push(sorted_array[i]);
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
        }
        return response;
    },
    searchInSortedArray(sorted_array, response_fields, search, search_column, exact_search) {
        if(search_column && search_column !== 'all') {
            this.fuse_options.keys = [search_column];
        }
        else {
            this.fuse_options.keys = Object.keys(response_fields);
        }
        if(exact_search) {
            this.fuse_options.threshold = 0.0;
        }
        else {
            this.fuse_options.threshold = 0.3;
        }
        if (this.fuse_options.keys.includes("updated_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("updated_at"), 1);
        }
        if (this.fuse_options.keys.includes("created_at")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("created_at"), 1);
        }
        if (this.fuse_options.keys.includes("tags")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("tags"), 1);
        }
        if (this.fuse_options.keys.includes("search_score")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("search_score"), 1);
        }
        if (this.fuse_options.keys.includes("id")) {
            this.fuse_options.keys.splice(this.fuse_options.keys.indexOf("id"), 1);
        }
        const fuse = new Fuse(sorted_array, this.fuse_options);
        let result = fuse.search(search);
        var response = [];
        for (let x = 0; x < result.length; x++) {
            result[x].item.search_score =
                100 - Math.floor(result[x].score * 100);
            response.push(result[x].item);
        }
        return response;
    },
    //UNIVERSAL FUNCTIONS
    async getFields(model, action) {
        let additionalModels = {};
        const response = await axios.get(store.getters.appUrl + "v2/fields?source=site&" + action + "=true&model_type=" + model);
        let data = response.data.data;
        let tags_required = false;
        let usual_fields = [];
        let custom_fields = [];
        //custom fix for models that have usual type field
        for (let x = 0; x < data.length; x++) {
            if (data[x].custom_field == 1) {
                if(data[x].field_type === 'select_single' || data[x].field_type === 'select_multiple') {
                    for (let i = 0; i < data[x].available_options.length; i++) {
                        data[x].available_options[i] = {
                            text: store.getters.translate(data[x].available_options[i]),
                            value: data[x].available_options[i],
                        };
                    }
                }
                custom_fields.push(data[x]);
            }
            else if(data[x].name === 'tags') {
                tags_required = true;
            }
            else {
                if(data[x].name === 'ambient_temperature_id') {
                    if(!additionalModels.ambient_temperatures) {
                        additionalModels.ambient_temperatures = await this.getAdditionalModels("ambienttemperatures", "id", ["code", "low", "high"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.ambient_temperatures, 'text');
                }
                else if(data[x].name === 'brand_id') {
                    if(!additionalModels.brands) {
                        additionalModels.brands = await this.getAdditionalModels("brands", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.brands, 'text');
                }
                else if(data[x].name === 'company_id') {
                    if(!additionalModels.companies) {
                        additionalModels.companies = await this.getAdditionalModels("companies", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.companies, 'text');
                }
                else if(data[x].name === 'conformity_mark_id') {
                    if(!additionalModels.conformity_marks) {
                        additionalModels.conformity_marks = await this.getAdditionalModels("conformitymarks", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.conformity_marks, 'text');
                }
                else if(data[x].name === 'country_of_birth_id') {
                    if(!additionalModels.countries) {
                        additionalModels.countries = await this.getAdditionalModels("countries", "country_id", ["country_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.countries, 'text');
                }
                else if(data[x].name === 'distribution_device_function_id') {
                    if(!additionalModels.distribution_device_functions) {
                        additionalModels.distribution_device_functions = await this.getAdditionalModels("distributiondevicefunctions", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.distribution_device_functions, 'text');
                }
                else if(data[x].name === 'distribution_panel_id') {
                    if(!additionalModels.distribution_panels) {
                        additionalModels.distribution_panels = await this.getAdditionalModels("distributionpanels", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.distribution_panels, 'text');
                }
                else if(data[x].name === 'inspection_certificate_id') {
                    if(!additionalModels.inspection_certificates) {
                        additionalModels.inspection_certificates = await this.getAdditionalModels("inspectioncertificates", "id", ["full_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.inspection_certificates, 'text');
                }
                else if(data[x].name === 'inspection_code_id') {
                    if(!additionalModels.inspection_codes) {
                        //description is commented for now due to Greg`s notes
                        //additionalModels.inspection_codes = await this.getAdditionalModels("inspectioncodes", "id", ["code", "description"]);
                        additionalModels.inspection_codes = await this.getAdditionalModels("inspectioncodes", "id", ["code"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.inspection_codes, 'text');
                }
                else if(data[x].name === 'inspection_institute_certification_id') {
                    if(!additionalModels.inspection_institute_certifications) {
                        additionalModels.inspection_institute_certifications = await this.getAdditionalModels("inspectioninstitutecertifications", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.inspection_institute_certifications, 'text');
                }
                else if(data[x].name === 'inspection_institute_id') {
                    if(!additionalModels.inspection_institutes) {
                        additionalModels.inspection_institutes = await this.getAdditionalModels("inspectioninstitutes", "id", ["full_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.inspection_institutes, 'text');
                }
                else if(data[x].name === 'inspection_zone_id') {
                    if(!additionalModels.inspection_zones) {
                        additionalModels.inspection_zones = await this.getAdditionalModels("inspectionzones", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.inspection_zones, 'text');
                }
                else if(data[x].name === 'ip_indication_id' || data[x].name === 'ip_indication_t_m_id') {
                    if(!additionalModels.ip_indications) {
                        additionalModels.ip_indications = await this.getAdditionalModels("ipindications", "id", ["name", "description"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.ip_indications, 'text');
                }
                else if(data[x].name === 'ip_type_id' || data[x].name === 'ip_type_t_m_id' || data[x].name === 'ip_type_ids') {
                    if(!additionalModels.ip_types) {
                        //description is commented for now due to Greg`s notes
                        //additionalModels.ip_types = await this.getAdditionalModels("iptypes", "id", ["name", "description"]);
                        additionalModels.ip_types = await this.getAdditionalModels("iptypes", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.ip_types, 'text');
                }
                else if(data[x].name === 'line_voltage_id') {
                    if(!additionalModels.line_voltage) {
                        additionalModels.line_voltage = await this.getAdditionalModels("linevoltage", "id", ["voltage", "ac_dc"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.line_voltage, 'text');
                }
                else if(data[x].name === 'nationality_id') {
                    if(!additionalModels.nationalities) {
                        additionalModels.nationalities = await this.getAdditionalModels("nationalities", "nationality_id", ["nationality_name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.nationalities, 'text');
                }
                else if(data[x].name === 'question_id') {
                    if(!additionalModels.questions) {
                        additionalModels.questions = await this.getAdditionalModels("inspectionquestions", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.questions, 'text');
                }
                else if(data[x].name === 'simple_equipment_type_ids') {
                    if(!additionalModels.simple_equipment_types) {
                        additionalModels.simple_equipment_types = await this.getAdditionalModels("simpleequipmenttypes", "id", ["name", "type"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.simple_equipment_types, 'text');
                }
                else if(data[x].name === 'simple_inspection_id') {
                    if(!additionalModels.simple_inspections) {
                        additionalModels.simple_inspections = await this.getAdditionalModels("simpleinspections", "id", ["number"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.simple_inspections, 'text');
                }
                else if(data[x].name === 'simple_inspection_item_id') {
                    if(!additionalModels.simple_inspection_items) {
                        additionalModels.simple_inspection_items = await this.getAdditionalModels("simpleinspectionitems", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.simple_inspection_items, 'text');
                }
                else if(data[x].name === 'simple_protection_method_id' || data[x].name === 'simple_protection_method_ids') {
                    if(!additionalModels.simple_protection_methods) {
                        additionalModels.simple_protection_methods = await this.getAdditionalModels("simpleprotectionmethods", "id", ["code"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.simple_protection_methods, 'text');
                }
                else if(data[x].name === 'zone_type_id' || data[x].name === 'zone_type_ids') {
                    if(!additionalModels.zone_types) {
                        additionalModels.zone_types = await this.getAdditionalModels("zonetypes", "id", ["name", "type"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.zone_types, 'text');
                }
                else if(data[x].name === 'initial_inspector_id' || data[x].name === 'after_remedial_inspector_id') {
                    if(!additionalModels.employees) {
                        additionalModels.employees = await this.getAdditionalModels("employees", "id", ["name"]);
                    }
                    data[x].available_options = lodash.sortBy(additionalModels.employees, 'text');
                }
                else if(data[x].field_type === 'select_single' || data[x].field_type === 'select_multiple') {
                    for (let i = 0; i < data[x].available_options.length; i++) {
                        data[x].available_options[i] = {
                            value: data[x].available_options[i],
                            text: store.getters.translate(data[x].available_options[i]),
                        };
                    }
                }
                usual_fields.push(data[x]);
            }
        }
        return { tags_required: tags_required, usual_fields: usual_fields, custom_fields: custom_fields };
    },
    async getAdditionalModels(model, option_id, option_name) {
        let models = [];
        const response = await axios.get(store.getters.appUrl + "v2/" + model + "?fields=" + option_name.join());
        if(response.data.data) {
            response.data.data.forEach((option) => {
                let text = "";
                for (let i = 0; i < option_name.length; i++) {
                    if(option[option_name[i]] && option_name[i] !== 'type') {
                        text += store.getters.translate(option[option_name[i]]) + " | ";
                    }
                }
                models.push({
                    value: option[option_id],
                    text: text.substring(0, text.length-3),
                    type: option.type //almost always null except 2 models: zone types and simple equipment types. Required to filter records by type, selected in another field
                });
            });
        }
        else {
            response.data.forEach((option) => {
                let text = "";
                for (let i = 0; i < option_name.length; i++) {
                    if(option[option_name[i]] && option_name[i] !== 'type') {
                        text += store.getters.translate(option[option_name[i]]) + " | ";
                    }
                }
                models.push({
                    value: option[option_id],
                    text: text.substring(0, text.length-3),
                    type: option.type //almost always null except 2 models: zone types and simple equipment types. Required to filter records by type, selected in another field
                });
            });
        }
        return models;
    },
    modelFieldsFilled(toasted, record, fields, ignore_fields = []) {
        let status = true;
        for (let i = 0; i < fields.length; i++) {
            if ((fields[i].name === 'first_name' || fields[i].name === 'last_name') && (!record[fields[i].name] || record[fields[i].name] === '')) {
                toasted.error(store.getters.translate("required_field") + ": " + store.getters.translate('name'));
                status = false;
            }
            else if (fields[i].mandatory && !ignore_fields.includes(fields[i].name) && fields[i].field_type !== 'boolean' && (!record[fields[i].name] || record[fields[i].name] === '')) {
                toasted.error(store.getters.translate("required_field") + ": " + store.getters.translate(fields[i].name));
                status = false;
            }
        }
        return status;
    },
    getCorrectPage(field_name) {
        if(field_name === 'simpledefect') {
            return { page: this.pages.simpledefects, modal_type: "create_new_record", files_required: true, multiple_files: true };
        }
        else if(field_name === 'brand_id') {
            return { page: this.pages.brands, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'distribution_panel_id') {
            return { page: this.pages.distributionpanels, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'distribution_device_function_id') {
            return { page: this.pages.distributiondevicefunctions, modal_type: "create_new_record_trans", files_required: false, multiple_files: false };
        }
        else if(field_name === 'inspection_institute_id') {
            return { page: this.pages.inspectioninstitutes, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'inspection_certificate_id') {
            return { page: this.pages.inspectioncertificates, modal_type: "create_new_record", files_required: true, multiple_files: true };
        }
        else if(field_name === 'inspection_code_id') {
            return { page: this.pages.inspectioncodes, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'ip_indication_id' || field_name === 'ip_indication_t_m_id') {
            return { page: this.pages.ipindications, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'ip_type_id' || field_name === 'ip_type_t_m_id' || field_name === 'ip_type_ids') {
            return { page: this.pages.iptypes, modal_type: "create_new_record_trans", files_required: false, multiple_files: false };
        }
        else if(field_name === 'line_voltage_id') {
            return { page: this.pages.linevoltage, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'simple_equipment_type_ids') {
            return { page: this.pages.simpleequipmenttypes, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
        else if(field_name === 'simple_protection_method_ids') {
            return { page: this.pages.simpleprotectionmethods, modal_type: "create_new_record_trans", files_required: false, multiple_files: false };
        }
        else if(field_name === 'zone_type_id' || field_name === 'zone_type_ids') {
            return { page: this.pages.zonetypes, modal_type: "create_new_record", files_required: false, multiple_files: false };
        }
    },
    replaceIDwithNameBaseTable(fields) {
        if(fields.after_remedial_inspector_id) {
            fields.after_remedial_inspector_name = "select_single";
            delete fields.after_remedial_inspector_id;
        }
        if(fields.ambient_temperature_id) {
            fields.ambient_temperature_name = "select_single";
            delete fields.ambient_temperature_id;
        }
        if(fields.brand_id) {
            fields.brand_name = "select_single";
            delete fields.brand_id;
        }
        if(fields.company_id) {
            fields.company_name = "select_single";
            delete fields.company_id;
        }
        if(fields.conformity_mark_id) {
            fields.conformity_mark_name = "select_single";
            delete fields.conformity_mark_id;
        }
        if(fields.distribution_panel_id) {
            fields.distribution_panel_name = "select_single";
            delete fields.distribution_panel_id;
        }
        if(fields.distribution_device_function_id) {
            fields.distribution_device_function_name = "select_single";
            delete fields.distribution_device_function_id;
        }
        if(fields.initial_inspector_id) {
            fields.initial_inspector_name = "select_single";
            delete fields.initial_inspector_id;
        }
        if(fields.inspection_certificate_id) {
            fields.inspection_certificate_name = "select_single";
            delete fields.inspection_certificate_id;
        }
        if(fields.inspection_code_id) {
            fields.inspection_code_name = "select_single";
            delete fields.inspection_code_id;
        }
        if(fields.inspection_institute_id) {
            fields.inspection_institute_name = "select_single";
            delete fields.inspection_institute_id;
        }
        if(fields.inspection_institute_certification_id) {
            fields.inspection_institute_certification_name = "select_single";
            delete fields.inspection_institute_certification_id;
        }
        if(fields.inspection_zone_id) {
            fields.inspection_zone_name = "select_single";
            delete fields.inspection_zone_id;
        }
        if(fields.ip_indication_id) {
            fields.ip_indication_name = "select_single";
            delete fields.ip_indication_id;
        }
        if(fields.ip_indication_t_m_id) {
            fields.ip_indication_t_m_name = "select_single";
            delete fields.ip_indication_t_m_id;
        }
        if(fields.ip_type_id) {
            fields.ip_type_name = "select_single";
            delete fields.ip_type_id;
        }
        if(fields.ip_type_t_m_id) {
            fields.ip_type_t_m_name = "select_single";
            delete fields.ip_type_t_m_id;
        }
        if(fields.line_voltage_id) {
            fields.line_voltage_name = "select_single";
            delete fields.line_voltage_id;
        }
        if(fields.simple_protection_method_id) {
            fields.simple_protection_method_name = "select_single";
            delete fields.simple_protection_method_id;
        }
        if(fields.question_id) {
            fields.question_name = "select_single";
            delete fields.question_id;
        }
        if(fields.simple_inspection_id) {
            fields.simple_inspection_name = "select_single";
            delete fields.simple_inspection_id;
        }
        if(fields.zone_type_id) {
            fields.zone_type_name = "select_single";
            delete fields.zone_type_id;
        }
        return fields;
    },
    replaceIDwithNameBaseTableInc(name) {
        //INSPECTION ZONE
        if(name === "simple_inspection_id") {
            return "simple_inspection_name";
        }
        //SIMPLE INSPECTION ITEM
        else if(name === "inspection_zone_id") {
            return "inspection_zone_name";
        }
        else if(name === "brand_id") {
            return "brand_name";
        }
        else if(name === "ip_type_id") {
            return "ip_type_name";
        }
        else if(name === "ip_indication_id") {
            return "ip_indication_name";
        }
        else if(name === "ip_type_t_m_id") {
            return "ip_type_t_m_name";
        }
        else if(name === "ip_indication_t_m_id") {
            return "ip_indication_t_m_name";
        }
        else if(name === "zone_type_id") {
            return "zone_type_name";
        }
        else if(name === "distribution_device_function_id") {
            return "distribution_device_function_name";
        }
        else if(name === "distribution_panel_id") {
            return "distribution_panel_name";
        }
        else if(name === "line_voltage_id") {
            return "line_voltage_name";
        }
        else if(name === "inspection_certificate_id") {
            return "inspection_certificate_name";
        }
        else if(name === "inspection_code_id") {
            return "inspection_code_name";
        }
        else if(name === "inspection_institute_id") {
            return "inspection_institute_name";
        }
        //DEFECTS
        else if(name === "simple_inspection_item_id") {
            return "simple_inspection_item_name";
        }
        else {
            return name;
        }
    },
    replaceLastChar(model) {
        if(model === 'linevoltage') {
            return model;
        }
        else if(model === 'company') {
            return 'companies';
        }
        else {
            return model + "s";
        }
    },
    async loadModel(toasted, model, model_id) {
        const response = await axios.get(store.getters.appUrl + "v2/" + model + "/" + model_id).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            let result = response.data;
            let modelsWithCustomFields = ["companies", "contacts", "employees"];
            if(modelsWithCustomFields.includes(model)) {
                if (result.custom_fields == null || Array.isArray(result.custom_fields) || typeof result.custom_fields != "object") {
                    result.custom_fields = {};
                }
            }
            return result;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async createModel(toasted, model, record) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model, record).catch((error) => { toasted.error(error.message); });
        if (response && response.status == 200) {
            toasted.success(store.getters.translate("success"));
            return response.data.id;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async saveModel(toasted, model, record) {
        const response = await axios.patch(store.getters.appUrl + "v2/" + model + "/" + record.id, record).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return true;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async deleteModel(toasted, model, model_id) {
        const response = await axios.delete(store.getters.appUrl + "v2/" + model + "/" + model_id).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("successfully_deleted"));
            return true;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async saveFile(toasted, model, formData) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model + "/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } }).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return response;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    async deleteFile(toasted, model, payload) {
        const response = await axios.post(store.getters.appUrl + "v2/" + model + "/deletefiles", payload).catch((error) => { toasted.error(error.message); });
        if (response && response.status === 200) {
            toasted.success(store.getters.translate("success"));
            return response;
        } else {
            toasted.error(response.message);
            return false;
        }
    },
    getLocalStorageValue(value_name) {
        if (localStorage.getItem(value_name)) {
            return JSON.parse(localStorage.getItem(value_name));
        }
        return null;
    },
    setLocalStorageValue(value_name, value) {
        localStorage.setItem(value_name, JSON.stringify(value));
    },
    getBreadcrumbs(module_name, current = null) {
        let response = [{
            title: "Dashboard",
            disabled: false
        }];
        if(module_name !== "dashboard") {
            response[0].to = "/";
            response[1] = {
                title: store.getters.translate(module_name)
            }
            if(current == null) {
                response[1].disabled = true;
            }
            else {
                response[1].disabled = false;
                response[1].href = "/" + module_name;
                response[2] = {
                    title: current,
                    disabled: true
                }
            }
        }
        return response;
    },
}

export default helpFunctions