<template>
  <v-expansion-panel v-if="showPanel()">
    <v-expansion-panel-title>
      <v-toolbar flat color="white" dense style="width:98%">
        <v-icon v-if="$icons[relation_name]" class="mr-2">{{ $icons[relation_name] }}</v-icon>
        <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}<small class="ml-1">({{ records.length }})</small></v-toolbar-title>
        <v-toolbar-title v-else>{{ $store.getters.translate(relation_name) }}<small class="ml-1">({{ records.length }})</small></v-toolbar-title>
        <!--SIMPLE INSPECTION FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'simpleinspectionitems'" color="primary" class="ml-10 mt-7" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_simpleinspectionitems" @click.stop=""/>
        <!--SIMPLE DEFECTS FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'simpledefects'" color="primary" class="ml-10 mt-7" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_simple_defects" @click.stop=""/>
        <!--GENERAL DEFECTS FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'generaldefects'" color="primary" class="ml-10 mt-7" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_general_defects" @click.stop=""/>
        <!--RIGHT PART START (BUTTONS)-->
        <v-spacer/>
        <!--EXCEL EXPORT-->
        <download-excel v-if="records.length > 0" style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :fields="export_fields" :data="getRecordsForExport" :name="relation_name + '.xls'">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon color="primary" size="large" class="ml-2" icon="mdi-file-excel"/>
        </download-excel>
        <!--LINK-->
        <base-link
            v-if="base_table_includable_options.link && selected.length == 0 && canEditBaseModel"
            :label="$store.getters.translate('link') + ' ' + $store.getters.translate(relation_name)"
            @refresh="$emit('refresh', true)"
            @change="linkOrCreateRecord"
            :module_name="relation_name"
            :excluded_ids="$lodash.map(records, 'id')"/>
        <!--DELETE-->
        <v-btn v-if="selected.length > 0 && canDelete()" @click.stop="bulkArchiveOrDetach" size="small" color="red">
          <span v-if="base_table_includable_options.detachable">{{ $store.getters.translate("detach") }}</span>
          <span v-else>{{ $store.getters.translate("delete") }}</span>
          <v-icon color="red" size="large" class="ml-2" icon="mdi-link-variant"/>
          {{ selected.length }}
        </v-btn>
        <!--CLOSE SIMPLE INSPECTION ITEMS-->
        <template v-if="relation_name === 'simpleinspectionitems' && selected.length > 0 && canEdit()">
          <v-btn @click.stop="$emit('close', selected);" size="small">
            {{ $store.getters.translate("close") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-check"/>
          </v-btn>
        </template>
        <!--CREATE-->
        <v-btn v-if="base_table_includable_options.create && canCreate()" @click.stop="$emit('create', true);" size="small">
          {{ $store.getters.translate("create") }}
          <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
        </v-btn>
      </v-toolbar>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-data-table
          :headers="headers"
          :items="getRecords"
          :loading="loading"
          item-value="id"
          selectable-key="id"
          return-object
          :show-select="!base_table_includable_options.no_select"
          v-model="selected"
          v-bind="footer_props"
          v-model:items-per-page="options.itemsPerPage"
          v-model:page="options.page"
          v-model:sort-by="options.sortBy"
          @update:current-items="current = $event"
          :custom-sort="customSort"
          :search="search">
        <template #top>
          <v-text-field
              color="primary"
              variant="outlined"
              density="compact"
              bg-color="white"
              v-if="base_table_includable_options.show_search"
              v-model="search"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-inner-icon="mdi-magnify"/>
        </template>
        <template #body="{ items }">
          <tr v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
            <td v-if="!base_table_includable_options.no_select">
              <v-checkbox color="primary" v-model="selected" :value="item" @click.shift="handleShiftClick(item)"/>
            </td>
            <td v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('edit', item, relation_name)">
              <!--CHECKBOXES/BOOLEAN VALUES-->
              <template v-if="response_fields[key] === 'boolean'">
                <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                <template v-else>0</template>
              </template>
              <!--DATES-->
              <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
              </template>
              <!--DROPDOWNS-->
              <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                <template v-if="Array.isArray(item[key])">
                  <template v-if="item[key].length > 1">
                    <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                      {{ elem }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                  </template>
                </template>
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </template>
              <!--LINKS-->
              <template v-else-if="response_fields[key] === 'link'">
                <a>
                  <v-avatar v-if="key === 'name' && item.person" class="mr-2" size="20">
                    <v-img style="border: 1px" :src="item.person.avatar ? item.person.avatar : '../../../assets/images/no-user.jpg'"/>
                  </v-avatar>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 50 }) }}
                </a>
              </template>
              <!--ID FIELDS-->
              <template v-else-if="key === 'id' || key === 'internal_id'">
                <a>{{ item[key] }}</a>
              </template>
              <!--TEXT FIELDS-->
              <template v-else>
                {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
              </template>
            </td>
            <!--ACTION BUTTONS-->
            <td style="text-align: right">
              <v-icon v-if="base_table_includable_options.copy && canCreate(item.id || 0)" @click="makeCopy(item)" size="small" class="mr-2" icon="mdi-content-copy"/>
              <v-icon v-if="canEdit(item.id || 0)" @click="$emit('edit', item, relation_name)" size="small" class="mr-2" icon="mdi-pencil"/>
              <v-icon
                  v-if="+item.id !== 0 && canCreatePermission()"
                  @click="showPermissionManager(item)"
                  size="small"
                  class="mr-2"
                  :class="[isCreatePermissionDisabled?'custom-disabled' : '']"
                  icon="mdi-lock-outline"/>
            </td>
          </tr>
          <tr v-if="records.length === 0">
            <td class="text-center text-grey" :colspan="headers.length + 1">
              <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
              <span v-else>{{ $store.getters.translate("loading") }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expansion-panel-text>
    <manage-by-roles :ref="'custom_permission_manager_' + relation_name" :selected_record="selected_record" @disableManaging="manageCreatePermissionDisabledStatus" @refresh="$emit('update')"/>
  </v-expansion-panel>
</template>

<script>
import BaseLink from "./BaseLink";
import ManageByRoles from "../../views/permissions/ManageByRoles";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';
import lodash from "lodash";

export default {
  components: {
    BaseLink,
    ManageByRoles,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  props: ["page", "parent_module_id", "relation_name", "label"],
  data() {
    return {
      isCreatePermissionDisabled: false,
      selected_record: null,
      loading: false,
      selected: [],
      records: [],
      search: "",
      options: {},
      footer_props: helpFunctions.footer_props,
      base_table_includable_options: {},
      headers: [],
      response_fields: [],
      current: [],
      export_fields: {},
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options");
    }
    else {
      this.options = helpFunctions.table_options;
    }
    this.base_table_includable_options = helpFunctions.base_table_includable_options[this.page.name][this.relation_name];
    this.getFields(helpFunctions.models[this.relation_name].toLowerCase());
  },
  methods: {
    showPermissionManager(record) {
      let ref = 'custom_permission_manager_' + this.relation_name;

      this.$refs[ref].managerOptions = {
        entityId: record.id,
        entityModel: helpFunctions.models[this.relation_name].toLowerCase(),
        disabled: [
          'entityItems',
          'entities',
        ]
      }

      this.$refs[ref].page.title = this.$store.getters.translate("manage_custom_permissions");
      this.$refs[ref].show = true;
    },
    canCreatePermission(id = 0) {
      return this.can("create", this.$createEntity('CustomPermission', {id}));
    },
    manageCreatePermissionDisabledStatus(isDisabled = true) {
      this.isCreatePermissionDisabled = isDisabled;
    },
    bulkArchiveOrDetach() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          var result = {
            model_id: this.parent_module_id,
            relation_name: this.relation_name,
            ids: this.$lodash.map(this.selected, "id"),
          };
          if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
            result.model = 'User';
          }
          else {
            result.model = this.page.model;
          }
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.$emit("update");
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    linkOrCreateRecord(objects) {
      this.loading = true;
      var result = {
        model_id: this.parent_module_id,
        relation_name: this.relation_name,
        ids: this.$lodash.map(objects, "id"),
        objects: objects,
      };
      if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
        result.model = 'User';
      }
      else {
        result.model = this.page.model;
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/bulk/attach", result)
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("success"));
            this.$emit("update");
          }).catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = helpFunctions.models[this.relation_name];
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
              .then((response) => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.$emit("update");
                this.$emit('edit', response.data, this.relation_name);
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    clearSearch() {
      this.search = "";
    },
    customSort(items, index, isDesc) {
      let field = index[0];
      let desc = isDesc[0];
      let result = this.$lodash.sortBy(items, field);
      if (field === "date_of_birth") {
        result = result.sort((a, b) => {
          a = moment(a["date_of_birth"], "DD-MM-YYYY").toDate();
          b = moment(b["date_of_birth"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_punched") {
        result = result.sort((a, b) => {
          a = moment(a["date_punched"], "DD-MM-YYYY").toDate();
          b = moment(b["date_punched"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_cleared") {
        result = result.sort((a, b) => {
          a = moment(a["date_cleared"], "DD-MM-YYYY").toDate();
          b = moment(b["date_cleared"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (desc) {
        return result.reverse();
      }
      return result;
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }

    },
    handleShiftClick(item) {
      if (this.selected.length === 1 || this.selected.length === 2) {
        const IDs = [this.selected[1]?.id ?? item.id, this.selected[0].id]    // @click.shift is triggered before v-model="selected" updates, so we need to form a list of IDs from the item.
        let start = this.current.findIndex(el => el.raw.id === Math.min(...IDs));       // getting the starting element index
        let end = this.current.findIndex(el => el.raw.id === Math.max(...IDs));       //  end element index
        if(start > end) {
          let tmp = end;
          end = start;
          start = tmp;
        }
        for (let i = start; i < end; i++) {
          if (!this.selected.find((el) => el.id === this.current[i].key)) {
            this.selected.push(this.current[i].raw);
          }
        }
      }
    },
    canEditBaseModel() {
      return this.can("edit", this.$createEntity(this.page.model, {id: 0}));
    },
    canCreate() {
      return this.can("create", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), {id: 0}));
    },
    canDelete(id = 0) {
      return this.can("delete", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), {id}));
    },
    canEdit(id = 0) {
      return this.can("edit", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), {id}));
    },
    showPanel(id = 0) {
      return this.can("show", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), {id}));
    },
    async getFields(module_name) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_in_search=true&model_type=" + module_name)
          .then((response) => {
            this.headers = [];
            this.response_fields = [];
            response.data.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.headers.push({
                title: this.$store.getters.translate(item.name),
                key: item.name,
                align: "start",
                class: 'sticky-header',
                sortable: true,
                value: item.name,
              });
              this.response_fields[item.name] = item.field_type;
            });
            this.headers.push({
              title: this.$store.getters.translate("actions"),
              sortable: false,
              align: "end",
              class: 'sticky-header',
              value: "actions",
            });
            this.getFieldsForExport(helpFunctions.models[this.relation_name].toLowerCase());
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async getFieldsForExport(module_name) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&model_type=" + module_name)
          .then((response) => {
            response.data.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.export_fields[this.$store.getters.translate(item.name)] = item.name;
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  computed: {
    getRecords() {
      if((this.relation_name === 'simpledefects' && this.options.hide_closed_simple_defects)) {
        return this.records.filter((record) => !record.closed);
      }
      else if((this.relation_name === 'generaldefects' && this.options.hide_closed_general_defects)) {
        return this.records.filter((record) => !record.closed);
      }
      else if(this.relation_name === 'simpleinspectionitems' && this.options.hide_closed_simpleinspectionitems) {
        return this.records.filter((record) => record.status !== 'Pass');
      }
      else {
        return this.records;
      }
    },
    getRecordsForExport() {
      let results = lodash.cloneDeep(this.records);
      results.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if(key === 'distribution_device_function_name') {
            item[key] = this.$store.getters.translate(value);
          }
        });
      });
      return results;
    },
  },
  watch: {
    options: {
      handler() {
        this.selected = [];
        helpFunctions.setLocalStorageValue(this.relation_name + "_includable_options", this.options);
      },
      deep: true,
    },
  },
};
</script>

<style>
/*FIX GREY BACKGROUND COLOR WHEN HOVERING OVER V-EXPANSION-PANEL-TITLE.*/
.v-expansion-panel {
  .v-expansion-panel-title:hover {
    color: transparent;
  }
}
.sticky-header {
  position: sticky;
  top: 65px !important;
  background-color: white;
  z-index: 1;
}
.v-data-table__wrapper {
  overflow: unset !important;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style> 